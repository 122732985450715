export const PUSH_PROJECT = 'PUSH_PROJECT'

// CRUD
export const CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST'
export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST'
export const REMOVE_PROJECT_REQUEST = 'REMOVE_PROJECT_REQUEST'
export const QUIT_PROJECT_REQUEST = 'QUIT_PROJECT_REQUEST'

export const CHANGE_PROJECT_COLOR = 'CHANGE_PROJECT_COLOR'
export const CHANGE_PROJECT_MEMBERS = 'CHANGE_PROJECT_MEMBERS'
export const CHANGE_PROJECT_NAME = 'CHANGE_PROJECT_NAME'

export const RESET_STATE_PROJECT = 'RESET_STATE_PROJECT'

export const SELECT_PROJECT = 'SELECT_PROJECT'

export const ADD_PROJECT_TO_FAVORITE = 'ADD_PROJECT_TO_FAVORITE'
export const REMOVE_PROJECT_FROM_FAVORITE = 'REMOVE_PROJECT_FROM_FAVORITE'
