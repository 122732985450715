<template>
  <div
    class="mt-3 tags-custom project-hover-close"
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.00417 3.33334C7.36872 3.33334 6.04293 4.65913 6.04293 6.29457C6.04293 7.93002 7.36872 9.25581 9.00417 9.25581C10.6396 9.25581 11.9654 7.93002 11.9654 6.29457C11.9654 4.65913 10.6396 3.33334 9.00417 3.33334ZM4.70959 6.29457C4.70959 3.92275 6.63234 2 9.00417 2C11.376 2 13.2987 3.92275 13.2987 6.29457C13.2987 8.6664 11.376 10.5891 9.00417 10.5891C6.63234 10.5891 4.70959 8.6664 4.70959 6.29457Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.12137 12.0406C4.7532 12.0405 4.39766 12.1748 4.12161 12.4184C3.84558 12.662 3.66805 12.9981 3.62238 13.3634C3.62237 13.3634 3.62238 13.3633 3.62238 13.3634L3.3394 15.6301C3.32561 15.7405 3.33544 15.8526 3.36826 15.9589C3.40109 16.0652 3.45614 16.1633 3.52978 16.2467C3.60341 16.3301 3.69394 16.3969 3.79536 16.4426C3.89677 16.4883 4.00675 16.5119 4.118 16.5119H12.6321C13.0003 16.5119 13.2988 16.8104 13.2988 17.1786C13.2988 17.5468 13.0003 17.8453 12.6321 17.8453H4.11829C4.11824 17.8453 4.11834 17.8453 4.11829 17.8453C3.81801 17.8453 3.52101 17.7815 3.24728 17.6581C2.9735 17.5346 2.7291 17.3543 2.53032 17.1292C2.33153 16.9041 2.1829 16.6393 2.09429 16.3523C2.00568 16.0654 1.97911 15.7629 2.01633 15.4649C2.01634 15.4649 2.01633 15.4649 2.01633 15.4649L2.29931 13.1982C2.38527 12.5103 2.71959 11.8774 3.23938 11.4187C3.75911 10.9601 4.42847 10.7071 5.12163 10.7073H5.3763C5.74449 10.7073 6.04297 11.0058 6.04297 11.3739C6.04297 11.7421 5.74449 12.0406 5.3763 12.0406L5.12137 12.0406Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.6666 12.9999C10.6666 12.6563 10.9763 12.3777 11.3584 12.3777H15.5014L14.824 11.7074C14.567 11.4531 14.5878 11.0596 14.8705 10.8284C15.1532 10.5972 15.5907 10.616 15.8476 10.8703L18 12.9999L15.8476 15.1296C15.5907 15.3839 15.1532 15.4026 14.8705 15.1714C14.5878 14.9403 14.567 14.5467 14.824 14.2925L15.5014 13.6221H11.3584C10.9763 13.6221 10.6666 13.3436 10.6666 12.9999Z"
        fill="currentColor"
      />
    </svg>
    <span class="rounded"> Передать Инспектору </span>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.inspector {
    @apply bg-[#FF912380] text-white cursor-pointer !important;
}
</style>
