export default {
  width: 64,
  height: 64,
  viewBox: '0 0 64 64',
  path: [
    'M60.021 49.5512L35.8542 8.23402C35.0658 6.85704 33.5859 6 31.9976 6C31.2262 6 30.4606 6.20569 29.7864 6.5885C29.1065 6.98274 28.5351 7.54839 28.1466 8.2226L3.97982 49.5512C3.12849 51.0025 3.11706 52.7394 3.95125 54.2021C4.76829 55.6191 6.21384 56.4704 7.83079 56.4704H56.1643C57.7813 56.4704 59.2325 55.6191 60.0439 54.1964C60.8838 52.7337 60.8723 50.9968 60.021 49.5512ZM7.92221 51.8652L31.9861 10.6794L56.0786 51.871L56.09 51.8995H7.93364V51.8824L7.92221 51.8652Z',
    'M29.5408 36.4346C29.6436 37.6345 30.5864 38.5772 31.7862 38.68C31.8548 38.6858 31.9291 38.6915 31.9977 38.6915C33.2661 38.6915 34.3402 37.703 34.4488 36.446L35.6487 25.7636C35.6772 25.5065 35.6772 25.2494 35.6487 24.9923C35.4487 23.1182 33.8831 21.707 31.9977 21.707C31.8662 21.707 31.7405 21.7127 31.6091 21.7298C29.5979 21.9412 28.1295 23.7524 28.3409 25.7636L29.5408 36.4346Z',
    'M32.0034 48.9474C34.2088 48.9474 36.0029 47.1533 36.0029 44.9479C36.0029 42.7424 34.2088 40.9484 32.0034 40.9484C29.7979 40.9484 28.0038 42.7424 28.0038 44.9479C28.0038 47.1533 29.7979 48.9474 32.0034 48.9474Z'
  ]
}
