<template>
  <div
    v-if="focus"
    class="mt-3 tags-custom dark:bg-gray-800 dark:text-gray-100 project-hover-close"
    @click="onClick"
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 44 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.47887 0H41.5211C42.8845 0 44 1.1195 44 2.48777V57.0416C44 58.0782 43.339 59.0318 42.3681 59.3843C42.0995 59.4879 41.8103 59.5294 41.5211 59.5294C40.7775 59.5294 40.0958 59.1977 39.6207 58.638L22 41.1088L4.37934 58.638C3.90423 59.1977 3.20188 59.5294 2.47887 59.5294C2.18967 59.5294 1.90047 59.4879 1.63192 59.3843C0.661033 59.0111 0 58.0782 0 57.0416V2.48777C0 1.1195 1.11549 0 2.47887 0ZM21.5922 11.4076C19.5672 11.4076 17.9255 13.0492 17.9255 15.0743C17.9255 17.0993 19.5672 18.7409 21.5922 18.7409C23.6173 18.7409 25.2589 17.0993 25.2589 15.0743C25.2589 13.0492 23.6173 11.4076 21.5922 11.4076Z"
        fill="#F2543F"
      />
    </svg>
    <span class="rounded"> В фокусе</span>
    <button
      class="btn-close-popover"
      @click="onClick"
    >
      <svg
        width="5"
        height="5"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.8483 2.34833C15.317 1.8797 15.317 1.11991 14.8483 0.651277C14.3797 0.182647 13.6199 0.182647 13.1513 0.651277L7.99981 5.80275L2.84833 0.651277C2.3797 0.182647 1.61991 0.182647 1.15128 0.651277C0.682647 1.11991 0.682647 1.8797 1.15128 2.34833L6.30275 7.4998L1.15128 12.6513C0.682647 13.1199 0.682647 13.8797 1.15128 14.3483C1.61991 14.817 2.3797 14.817 2.84833 14.3483L7.99981 9.19686L13.1513 14.3483C13.6199 14.817 14.3797 14.817 14.8483 14.3483C15.317 13.8797 15.317 13.1199 14.8483 12.6513L9.69686 7.4998L14.8483 2.34833Z"
          fill="black"
          fill-opacity="0.5"
        />
      </svg>
    </button>
  </div>
  <div
    v-else
    class="mt-3 tags-custom dark:bg-gray-800 dark:text-gray-100 project-hover-close"
    @click="onClick"
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 66 89"
      fill="none"
      class="dark:text-gray-100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M61.7494 0.599998H4.2508C2.24291 0.599998 0.600098 2.24871 0.600098 4.2638V84.6068C0.600098 86.1334 1.57362 87.5073 3.00348 88.0569C3.39897 88.2095 3.82489 88.2706 4.2508 88.2706C5.31559 88.2706 6.34996 87.7821 7.04968 86.9577L33.0001 61.142L58.9505 86.9577C59.6503 87.7821 60.6542 88.2706 61.7494 88.2706C62.1753 88.2706 62.6012 88.2095 62.9967 88.0569C64.4266 87.5378 65.4001 86.1334 65.4001 84.6068V4.2638C65.4001 2.24871 63.7573 0.599998 61.7494 0.599998ZM30.2012 54.3122L6.70151 76.9619V6.72761H59.2987V76.9619L35.799 54.3122C35.0993 53.4878 34.0953 52.9993 33.0001 52.9993C31.9049 52.9993 30.9009 53.4878 30.2012 54.3122Z"
        fill="black"
        fill-opacity="0.5"
      />
    </svg>
    <span class="rounded"> В фокус</span>
  </div>
</template>

<script>
export default {
  props: {
    focus: Boolean
  },
  emits: ['toggleFocus'],
  methods: {
    onClick () {
      this.$emit('toggleFocus')
    }
  }
}
</script>

<style scoped></style>
