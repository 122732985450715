<template>
  <ListBlocItem
    :title="reglament.name"
    :sub-title="author"
    sub-title-color="#7E7E80"
    :right-icon="iconCheck"
  >
    <svg
      class="flex-none"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5456 4.70833L13.2956 0.458333C13.1706 0.333333 12.9831 0.25 12.7956 0.25C12.6081 0.25 12.4206 0.333333 12.2956 0.458333L10.1081 2.64583C9.83728 2.91667 9.83728 3.375 10.1081 3.64583L10.1289 3.66667L4.64978 6.39583C4.50395 6.47917 4.37895 6.60417 4.31645 6.77083L0.316448 16.7708C0.316448 16.7917 0.295615 16.8125 0.295615 16.8333C0.274781 16.875 0.253948 16.9167 0.253948 16.9583C0.233115 17.1458 0.295615 17.3333 0.420615 17.4792C0.545615 17.6667 0.753948 17.75 0.962281 17.75C1.00395 17.75 1.04561 17.75 1.08728 17.7292C1.10811 17.7292 1.12895 17.7292 1.14978 17.7083C1.17061 17.7083 1.19145 17.6875 1.21228 17.6875L11.2123 13.6875C11.3789 13.625 11.5039 13.5 11.5873 13.3542L14.3164 7.875L14.3373 7.89583C14.4623 8.02083 14.6498 8.10417 14.8373 8.10417C15.0248 8.10417 15.2123 8.02083 15.3373 7.89583L17.5456 5.70833C17.8164 5.4375 17.8164 4.97917 17.5456 4.70833ZM7.67061 10.0417C7.67061 9.875 7.79561 9.75 7.96228 9.75C8.12895 9.75 8.25395 9.875 8.25395 10.0417C8.25395 10.2083 8.12895 10.3333 7.96228 10.3333C7.79561 10.3333 7.67061 10.1875 7.67061 10.0417ZM3.89978 15.1042L7.35811 11.6458C7.54561 11.7083 7.75395 11.75 7.96228 11.75C8.89978 11.75 9.67061 10.9792 9.67061 10.0417C9.67061 9.10417 8.89978 8.33333 7.96228 8.33333C7.02478 8.33333 6.25395 9.10417 6.25395 10.0417C6.25395 10.25 6.29561 10.4583 6.35811 10.6458L2.89978 14.1042L5.52478 7.54167L11.1706 4.70833L13.2748 6.8125L10.4623 12.4792L3.89978 15.1042ZM11.6081 3.14583L12.7956 1.95833L16.0456 5.20833L14.8581 6.39583L11.6081 3.14583Z"
        fill="#7E7E80"
      />
    </svg>
  </ListBlocItem>
</template>

<script>
import ListBlocItem from '@/components/Common/ListBlocItem.vue'

export default {
  components: {
    ListBlocItem
  },
  props: {
    reglament: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    iconCheck () {
      if (this.reglament.is_passed) return 'check'
      return ''
    },
    employeesByEmail () {
      return this.$store.state.employees.employeesByEmail
    },
    author () {
      const currentUserEmail = this.$store.state.user.user.current_user_email.toLowerCase()
      if (this.reglament.email_creator.toLowerCase() === currentUserEmail) return 'Мой'
      return ''
    }
  }
}
</script>

<style>

</style>
