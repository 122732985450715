export const PUSH_BOARD = 'PUSH_BOARD'

// CRUD
export const CREATE_BOARD_REQUEST = 'CREATE_BOARD_REQUEST'
export const UPDATE_BOARD_REQUEST = 'UPDATE_BOARD_REQUEST'
export const REMOVE_BOARD_REQUEST = 'REMOVE_BOARD_REQUEST'
export const QUIT_BOARD_REQUEST = 'QUIT_BOARD_REQUEST'

export const RESET_STATE_BOARD = 'RESET_STATE_BOARD'

export const SELECT_BOARD = 'SELECT_BOARD'
export const SHOW_BOARD_ARCHIVE = 'SHOW_BOARD_ARCHIVE'
export const SHOW_BOARD_MY_CARDS_WHERE_IAM_RESPONSIBLE =
  'SHOW_BOARD_MY_CARDS_WHERE_IAM_RESPONSIBLE'
export const SHOW_BOARD_CARDS_WITH_NO_RESPONSIBLE =
  'SHOW_BOARD_CARDS_WITH_NO_RESPONSIBLE'
export const SHOW_BOARD_MY_CREATED_CARDS = 'SHOW_BOARD_MY_CREATED_CARDS'
export const SHOW_SEARCH_CARDS = 'SHOW_SEARCH_CARDS'
export const BOARD_CLEAR_FILTER = 'BOARD_CLEAR_FILTER'

export const CHANGE_BOARD_NAME = 'CHANGE_BOARD_NAME'
export const CHANGE_BOARD_COLOR = 'CHANGE_BOARD_COLOR'
export const CHANGE_BOARD_MEMBERS = 'CHANGE_BOARD_MEMBERS'

export const ADD_STAGE_BOARD_REQUEST = 'ADD_STAGE_BOARD_REQUEST'
export const RENAME_STAGE_BOARD_REQUEST = 'RENAME_STAGE_BOARD_REQUEST'
export const DELETE_STAGE_BOARD_REQUEST = 'DELETE_STAGE_BOARD_REQUEST'
export const CHANGE_COLOR_STAGE_BOARD_REQUEST =
  'CHANGE_COLOR_STAGE_BOARD_REQUEST'
export const CHANGE_ORDER_STAGE_BOARD_REQUEST =
  'CHANGE_ORDER_STAGE_BOARD_REQUEST'

export const ADD_BOARD_TO_FAVORITE = 'ADD_BOARD_TO_FAVORITE'
export const REMOVE_BOARD_FROM_FAVORITE = 'REMOVE_BOARD_FROM_FAVORITE'

export const ADD_STAGE_BOARD = 'ADD_STAGE_BOARD'
export const DELETE_STAGE_BOARD = 'DELETE_STAGE_BOARD'
export const RENAME_STAGE_BOARD = 'RENAME_STAGE_BOARD'
export const CHANGE_ORDER_STAGE_BOARD = 'CHANGE_ORDER_STAGE_BOARD'
