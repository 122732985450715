export default {
  width: 30,
  height: 30,
  viewBox: '0 0 30 30',
  path: [
    'M26 12.3422C26 11.6962 25.4763 11.1725 24.8303 11.1725H5.16971C4.5237 11.1725 4 11.6962 4 12.3422C4 12.9882 4.5237 13.5119 5.16971 13.5119H24.8303C25.4763 13.5119 26 12.9882 26 12.3422Z',
    'M20.3657 6.16971C20.3657 5.5237 19.842 5 19.196 5H5.16971C4.5237 5 4 5.5237 4 6.16971C4 6.81573 4.5237 7.33942 5.16971 7.33942H19.196C19.842 7.33942 20.3657 6.81573 20.3657 6.16971Z',
    'M22.5386 18.5624C22.5386 17.9164 22.0149 17.3927 21.3688 17.3927H5.16971C4.5237 17.3927 4 17.9164 4 18.5624C4 19.2084 4.5237 19.7321 5.16971 19.7321H21.3688C22.0149 19.7321 22.5386 19.2084 22.5386 18.5624Z',
    'M14.0494 24.7827C14.0494 24.1367 13.5257 23.613 12.8797 23.613H5.16971C4.5237 23.613 4 24.1367 4 24.7827C4 25.4287 4.5237 25.9524 5.16971 25.9524H12.8797C13.5257 25.9524 14.0494 25.4287 14.0494 24.7827Z'
  ]
}
