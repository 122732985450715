export const REGLAMENT_REQUEST = 'REGLAMENT_REQUEST'
export const REGLAMENTS_REQUEST = 'REGLAMENTS_REQUEST'
export const CREATE_REGLAMENT_REQUEST = 'CREATE_REGLAMENT_REQUEST'
export const UPDATE_REGLAMENT_REQUEST = 'UPDATE_REGLAMENT_REQUEST'
export const DELETE_REGLAMENT_REQUEST = 'DELETE_REGLAMENT_REQUEST'

export const GET_USERS_REGLAMENT_ANSWERS = 'GET_USERS_REGLAMENT_ANSWERS'
export const DELETE_USERS_REGLAMENT_ANSWERS = 'DELETE_USERS_REGLAMENT_ANSWERS'
export const CRATE_USER_REGLAMENT_ANSWER = 'CRATE_USER_REGLAMENT_ANSWER'
export const GET_REGLAMENTS_BY_USER = 'GET_REGLAMENTS_BY_USER'

// мутации
export const REGLAMENT_CHANGE_REGLAMENTS = 'REGLAMENT_CHANGE_REGLAMENTS'

export const REGLAMENT_SUCCESS = 'REGLAMENT_SUCCESS'
export const REGLAMENT_RESTORE_SELECTED = 'REGLAMENT_RESTORE_SELECTED'

export const REGLAMENT_DELETE_ANSWER = 'REGLAMENT_DELETE_ANSWER'
export const REGLAMENT_PUSH_ANSWER = 'REGLAMENT_PUSH_ANSWER'
export const REGLAMENT_UPDATE_ANSWER = 'REGLAMENT_UPDATE_ANSWER'
export const REGLAMENT_UPDATE_ANSWER_NAME = 'REGLAMENT_UPDATE_ANSWER_NAME'
export const REGLAMENT_SELECT_ANSWER = 'REGLAMENT_SELECT_ANSWER'
export const REGLAMENT_SET_RIGHT_ANSWER = 'REGLAMENT_SET_RIGHT_ANSWER'

export const REGLAMENT_PUSH_QUESTION = 'REGLAMENT_PUSH_QUESTION'
export const REGLAMENT_UPDATE_QUESTION_NAME = 'REGLAMENT_UPDATE_QUESTION_NAME'
export const REGLAMENT_UPDATE_QUESTION = 'REGLAMENT_UPDATE_QUESTION'
export const REGLAMENT_DELETE_QUESTION = 'REGLAMENT_DELETE_QUESTION'
