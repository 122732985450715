<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.1053 6.19143C12.2299 6.06141 12.4022 5.9879 12.5822 5.9879L17.3518 5.9879C17.7166 5.9879 18.0124 6.28367 18.0124 6.64852V11.4181C18.0124 11.5982 17.9389 11.7704 17.8089 11.895L11.8928 17.5646C11.6333 17.8133 11.2227 17.8089 10.9686 17.5548L6.44553 13.0317C6.19141 12.7776 6.18705 12.367 6.4357 12.1075L12.1053 6.19143ZM11.3464 5.07372L5.03431 11.6603C4.53701 12.1792 4.54574 13.0005 5.05398 13.5087L10.4916 18.9463C10.9998 19.4546 11.8211 19.4633 12.3401 18.966L18.9266 12.6539C19.1866 12.4047 19.3337 12.0601 19.3337 11.7V5.9879C19.3337 5.2582 18.7421 4.66666 18.0124 4.66666H12.3003C11.9402 4.66666 11.5956 4.81369 11.3464 5.07372ZM13.643 8.84632C13.8449 8.64439 14.1127 8.53321 14.3985 8.53321C14.6844 8.53321 14.9521 8.64439 15.1541 8.84632C15.356 9.04825 15.4672 9.31598 15.4672 9.60186C15.4672 9.88774 15.356 10.1555 15.1541 10.3574C14.9521 10.5593 14.6844 10.6705 14.3985 10.6705C14.1127 10.6705 13.8449 10.5593 13.643 10.3574C13.4411 10.1555 13.3299 9.88774 13.3299 9.60186C13.3299 9.31598 13.4411 9.04825 13.643 8.84632Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#606061'
    }
  }
}
</script>
