export const BOARD_CARDS_REQUEST = 'BOARD_CARDS_REQUEST'
export const BOARD_CARDS_SUCCESS = 'BOARD_CARDS_SUCCESS'
export const BOARD_CARDS_ADDSTAGE = 'BOARD_CARDS_ADDSTAGE'
export const BOARD_CARDS_RENAME_STAGE = 'BOARD_CARDS_RENAME_STAGE'
export const BOARD_CARDS_DELETE_STAGE = 'BOARD_CARDS_DELETE_STAGE'
export const BOARD_CARDS_CHANGE_COLOR_STAGE = 'BOARD_CARDS_CHANGE_COLOR_STAGE'
export const BOARD_CARDS_CHANGE_ORDER_STAGE = 'BOARD_CARDS_CHANGE_ORDER_STAGE'
export const SELECT_CARD = 'SELECT_CARD'
export const ADD_CARD = 'ADD_CARD'

export const DELETE_CARD = 'DELETE_CARD'
export const MOVE_CARD = 'MOVE_CARD'

export const CHANGE_CARD = 'CHANGE_CARD'

export const CHANGE_CARD_RESPONSIBLE_USER = 'CHANGE_CARD_RESPONSIBLE_USER'
export const CHANGE_CARD_NAME = 'CHANGE_CARD_NAME'
export const CHANGE_CARD_COMMENT = 'CHANGE_CARD_COMMENT'
export const CHANGE_CARD_BUDGET = 'CHANGE_CARD_BUDGET'
export const CHANGE_CARD_COLOR = 'CHANGE_CARD_COLOR'
export const CHANGE_CARD_COVER = 'CHANGE_CARD_COVER'
export const CHANGE_CARD_CLEAR_COVER = 'CHANGE_CARD_CLEAR_COVER'
