<template>
  <div
    class="flex items-center justify-center rounded-[8px] border border-black/12 cursor-pointer h-[48px] order-last"
  >
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="dark:text-gray-100"
    >
      <rect
        x="0"
        y="0"
        width="30"
        height="30"
        rx="7"
        fill="#e8eaed"
      />
      <path
        d="M15.8302 10.4475L15.8999 10.4434C16.0457 10.4434 16.1864 10.4969 16.2953 10.5938C16.4043 10.6907 16.4739 10.8242 16.4909 10.969L16.4951 11.0386V15.2053H20.6618C20.8076 15.2053 20.9483 15.2588 21.0572 15.3557C21.1662 15.4526 21.2358 15.5861 21.2528 15.7309L21.257 15.8005C21.257 15.9463 21.2035 16.087 21.1066 16.196C21.0097 16.3049 20.8762 16.3745 20.7314 16.3916L20.6618 16.3957H16.4951V20.5624C16.4951 20.7082 16.4416 20.8489 16.3447 20.9579C16.2478 21.0668 16.1143 21.1364 15.9695 21.1535L15.8999 21.1576C15.7541 21.1576 15.6134 21.1041 15.5044 21.0072C15.3955 20.9103 15.3259 20.7768 15.3088 20.6321L15.3046 20.5624V16.3957H11.138C10.9922 16.3957 10.8515 16.3422 10.7425 16.2453C10.6336 16.1484 10.5639 16.0149 10.5469 15.8701L10.5427 15.8005C10.5427 15.6547 10.5963 15.514 10.6932 15.405C10.79 15.2961 10.9235 15.2265 11.0683 15.2094L11.138 15.2053H15.3046V11.0386C15.3046 10.8928 15.3582 10.7521 15.4551 10.6431C15.5519 10.5342 15.6854 10.4646 15.8302 10.4475L15.8999 10.4434L15.8302 10.4475Z"
        fill="#4c4c4d"
      />
    </svg>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
